import { Injectable } from '@angular/core';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { MessageConstants } from '../shared/model/MessageConstants';
@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    parser = new DOMParser();
    constructor(private ngxXml2jsonService: NgxXml2jsonService){}
    public gatewayError(error): any{
        let errorMessage = '';
        
        if (error.status == '401') {
            errorMessage = MessageConstants.TIME_OUT;
        }else if (error.status == '404') {
            errorMessage = MessageConstants.UNABLE_TO_PROCESS;   
        }else if (error.status == '500') {
            errorMessage = MessageConstants.INTERNAL_SERVER_ERROR;
        }else if (error.status == '504') {
            errorMessage = MessageConstants.DASHBOARD_TIME_OUT_504;
        }else{
            errorMessage = error.error.errors[0].errorMessage;
        }
        return errorMessage;
    }

   public convertJSON(error){
         let customError = '';
         let result: any = '';
        if(error.charAt(0) == '<'){
            result= this.ngxXml2jsonService.xmlToJson(this.parser.parseFromString(error,'application/xml'));   
        
            if (result.ErrorResponse.httpStatusCode == '404') {
                customError = MessageConstants.UNABLE_TO_PROCESS;
            }else if (result.ErrorResponse.httpStatusCode == '500') {
                customError = MessageConstants.INTERNAL_SERVER_ERROR;
            }else if(result){
                customError = result.ErrorResponse.errors.errors.errorMessage;
            }
        }
        return customError;
    }

}
