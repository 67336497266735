import {
  Component,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { NotificationService, SharedService } from "src/app/services";
import { NgxXml2jsonService } from "ngx-xml2json";
import { MessageConstants } from "../model/MessageConstants";
import * as papa from "papaparse";
import { TimeESTEDTPipe } from 'src/app/shared/pipes/time-est-edt.pipe';

@Component({
  selector: "app-export-report",
  templateUrl: "./export-report.component.html",
  styleUrls: ["./export-report.component.scss"],
  providers: [TimeESTEDTPipe]
})
export class ExportReportComponent implements OnInit {
  header: String = "Export Report";
  bodyMessage: String =
    "Kindly select the type of report you want to export as CSV.";
  bodyMessageLoading: String = "Your report is being generated.";
  readyMessage: String = "Your report is ready to download.";
  initialPercent: any = "0%";
  @Input() version: String;
  @Input() type: String;
  @Input() fromDate: String;
  @Input() toDate: String;
  @Input() reportFor: String;
  @Input() summaryHeader: any;
  @Input() summaryBody: any;
  loading: boolean = false;
  apiLoading: boolean = false;
  parser = new DOMParser();
  input: any;
  dialogueBox: any;
  csvErrorMessage: any;
  progressCSVDownload: number;
  downloadFlag: boolean = true;
  csvHeaderColumns = [
    { label: "Notification ID", data: "notificationId", class: "first-col" },
    {
      label: "Notification Created Date",
      data: "insertTimeStamp",
      class: "hide-on-xs",
    },
    { label: "Subscriber", data: "subscriberId", class: "hide-on-xs" },
    { label: "Merchant ID", data: "merchantId", class: "hide-on-xs" },
    { label: "DBA Name", data: "dbaName", class: "hide-on-xs" },
    { label: "Event Type", data: "eventTypeName", class: "hide-on-xs" },
    { label: "Terminal ID", data: "terminalId", class: "hide-on-xs" },
    {
      label: "Terminal Number",
      data: "terminalNumber",
      class: "hide-on-small",
    },
    {
      label: "Terminal Type",
      data: "terminalDescription",
      class: "hide-on-xs",
    },
    { label: "Lease ID", data: "leaseId", class: "hide-on-small" },
    { label: "Status", data: "status", class: "hide-on-xs" },
    { label: "Status Message", data: "responseMessage", class: "hide-on-xs" },
    { label: "Updated Time", data: "updatedTimeStamp", class: "hide-on-small" },
  ];
  exportDataCSV: any = [];
  datavalues: any = [];
  totalRowCount = 0;
  result: any;
  public subscriberData: any;
  eventsData: any;
  fileName: any;
  constructor(
    public dialogref: MatDialogRef<ExportReportComponent>,
    private _notificationService: NotificationService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) data,
    private ngxXml2jsonService: NgxXml2jsonService,
    private timeESTEDTPipe: TimeESTEDTPipe
  ) {
    this.input = data[0];
    this.dialogueBox = dialogref;
  }

  ngOnInit() {
    this.version = this.input.version;
    this.type = this.input.type;
    this.fromDate = this.input.fromDate;
    this.toDate = this.input.toDate;
    this.reportFor = this.input.reportFor;
    this.summaryHeader = this.input.summaryHeader;
    this.summaryBody = this.input.summaryBody;
    if (this.version == "V2") {
      this.eventsData = JSON.parse(localStorage.getItem("eventDataV2"));
      this.subscriberData = JSON.parse(
        localStorage.getItem("subscriberDataV2")
      );
    } else {
      this.eventsData = JSON.parse(localStorage.getItem("eventDataV1"));
      this.subscriberData = JSON.parse(
        localStorage.getItem("subscriberDataV1")
      );
    }
  }

  detailedReport() {
    switch (this.reportFor) {
      case "statusCount":
        this.detailedReportForStatusCount();
        break;
      case "eventCount":
        this.detailedReportForEventCount();
        break;
      case "subscriberCount":
        this.detailedReportForSubscriberCount();
        break;
      default:
        this.dialogueBox.close();
        break;
    }
  }

  summaryReport() {
    switch (this.reportFor) {
      case "statusCount":
        this.summaryReportForStatusCount();
        break;
      case "eventCount":
        this.summaryReportForEventCount();
        break;
      case "subscriberCount":
        this.summaryReportForSubscriberCount();
        break;
      default:
        this.dialogueBox.close();
        break;
    }
  }

  detailedReportForStatusCount() {
    this.apiLoading = true;
    this.loading = true;
    this.fileName = "NotificationStatusDetailedReport";
    this.generateDetailedReport("NotificationStatusDetailedReport");
  }

  summaryReportForStatusCount() {
    this.apiLoading = true;
    this.loading = true;
    this.summaryHeader = ["Status", "Count"];
    this.generateCSVFile(this.summaryHeader, "NotificationStatusSummaryReport");
  }

  detailedReportForEventCount() {
    this.apiLoading = true;
    this.loading = true;
    this.fileName = "EventDetailedReport";
    this.generateDetailedReport("EventDetailedReport");
  }

  summaryReportForEventCount() {
    this.apiLoading = true;
    this.loading = true;
    this.summaryHeader = ["EventName", "Count"];
    this.generateCSVFile(this.summaryHeader, "EventStatusSummaryReport");
  }

  detailedReportForSubscriberCount() {
    this.apiLoading = true;
    this.loading = true;
    this.fileName = "SubscriberDetailedReport";
    this.generateDetailedReport("SubscriberDetailedReport");
  }

  summaryReportForSubscriberCount() {
    this.apiLoading = true;
    this.loading = true;
    this.generateCSVFile(this.summaryHeader, "SubscriberStatusSummaryReport");
  }

  generateCSVFile(heading: any, fileName: any) {
    this.csvHeaderColumns = this.summaryHeader;
    this.fileName = fileName;
    this.csvDataIntoFile(this.summaryBody, 1, false, "summary");
    this.apiLoading = false;
    this.loading = false;
    this.dialogueBox.close();
  }

  generateDetailedReport(fileName: any) {
    this.progressCSVDownload = 0;
    var rowCount = 0;
    const obj = this.setSearchNotificationParameters();
    const csvHeaders = [];
    this.csvHeaderColumns.forEach((obj) => {
      csvHeaders.push(obj.label);
    });
    this._notificationService.search(obj).subscribe((result) => {
      if (result.data && result.data.notifications) {
        rowCount = result.paginationResponse.totalRowCount;
        this.totalRowCount = result.paginationResponse.totalRowCount;

        var modal = document.getElementById("export-div")!;
        modal.style.display = "block";
        var span = document.getElementsByClassName("close")[0]!;
        this.callCSVDetails();
      }
    });
  }
  /**Async call to form CSV data of multiple files for Detailed report**/
  async callCSVDetails() {
    this.csvErrorMessage = null;
    this.exportDataCSV = [];
    this.downloadFlag = true;

    var initialLimitValue: number = 1000;
    if(this.version == "V2"){
      initialLimitValue = 1000;
    }else {
      initialLimitValue = 5000;
    }
    this.progressCSVDownload = 0;
    var offsetvalue: number = 0;
    var varValue = 0;
    var csvPageNumber = 1;
    let iterationCount = Math.ceil(this.totalRowCount / initialLimitValue);
    var increment = 1 / iterationCount;
    let comparevalue = 0;
    if (this.totalRowCount > initialLimitValue) {
      for (let index = 0; index < iterationCount; index++) {
        offsetvalue = initialLimitValue * (csvPageNumber - 1) + 1;
        let obj = this.setPaginationNotificationParameters(
          offsetvalue,
          initialLimitValue,
          true
        );
        let responseData = await this.getCSVData(obj);
        let dt = responseData.data.notifications;
        csvPageNumber = csvPageNumber + 1;
        comparevalue = comparevalue + 1;
        dt.forEach((csvdata) => {
          if (csvdata.status === 'UNSENT') {
            csvdata.status = 'ON HOLD'
          }
          this.exportDataCSV.push(csvdata);
        });
        varValue = varValue + increment;
        this.progressCSVDownload = varValue;
        if (iterationCount == comparevalue) {
          this.progressCSVDownload = 1;
          this.downloadFlag = false;
        }
      }
    } else {
      this.progressCSVDownload = 0.5;
      let obj = this.setSearchNotificationParameters();
      this._notificationService.search(obj).subscribe(
        (result) => {
          if (result.data && result.data.notifications) {
            let dt = result.data.notifications;
            dt.forEach((csvdata) => {
              if (csvdata.status === 'UNSENT') {
                csvdata.status = 'ON HOLD'
              }
              this.exportDataCSV.push(csvdata);
            });
            this.progressCSVDownload = 1;
            this.downloadFlag = false;
          }
        },
        (error) => {
          if (error.error.charAt(0) == "<") {
            this.result = this.ngxXml2jsonService.xmlToJson(
              this.parser.parseFromString(error.error, "application/xml")
            );
            if (this.result.DefaultResponse.httpStatusCode == "500") {
              this.csvErrorMessage = MessageConstants.INTERNAL_SERVER_ERROR;
            }
          }
        }
      );
    }
  }

  public getCSVData(obj: any): Promise<any> {
    return this._notificationService
      .search(obj)
      .toPromise()
      .catch((error) => {
        if (error.error.charAt(0) == "<") {
          this.result = this.ngxXml2jsonService.xmlToJson(
            this.parser.parseFromString(error.error, "application/xml")
          );
          if (this.result.DefaultResponse.httpStatusCode == "500") {
            this.csvErrorMessage = MessageConstants.INTERNAL_SERVER_ERROR;
          }
        }
      });
  }

  /**On click of download for Detailed report**/
  onCSVDownload() {
    var multipleFileFlag: boolean = false;
    let chunkCount = Math.ceil(this.exportDataCSV.length / 950000);
    let index1 = 0;
    let startIndex = 0;
    let initialValue = 950000;
    let fileNumber = 0;
    if (chunkCount == 1) {
      this.datavalues = this.exportDataCSV.slice(startIndex, initialValue);
      this.csvDataIntoFile(this.datavalues, "", multipleFileFlag, "detailed");
    } else {
      multipleFileFlag = true;
      for (let index = 0; index < chunkCount; index++) {
        this.datavalues = this.exportDataCSV.slice(index1, initialValue);
        if (this.datavalues != null) {
          index1 = initialValue;
          initialValue = index1 + 950000;
          fileNumber = fileNumber + 1;
          this.csvDataIntoFile(
            this.datavalues,
            fileNumber,
            multipleFileFlag,
            "detailed"
          );
        }
      }
    }
  }
  /**CSV download for Summary and Detailed report**/
  csvDataIntoFile(csvFileData, fileNumber, multipleFileFlag, reportType) {
    const fields = [];
    this.csvHeaderColumns.forEach((obj) => {
      fields.push(obj.label);
    });
    const data = [];
    let csv: any;
    if (reportType === "summary") {
      csv = papa.unparse({
        fields: this.csvHeaderColumns,
        data: this.summaryBody,
      });
    } else {
      csvFileData.forEach((fields) => {
        const o = {};
        this.csvHeaderColumns.forEach((obj) => {
          if (
            obj.data === "insertTimeStamp" ||
            obj.data === "updatedTimeStamp"
          ) {
            let dt = moment(fields[obj.data]).format("YYYY-MM-DD HH:mm:ss");
            let objData = dt;
            o[obj.label] = objData;
          } else if (obj.data === "merchantId") {
            let objDataMerchant =
              fields[obj.data] === undefined ? null : fields[obj.data] + "\t";
            o[obj.label] = objDataMerchant;
          } else {
            o[obj.label] = fields[obj.data];
          }
        });
        data.push(o);
      });
      csv = papa.unparse({
        fields: fields,
        data: data,
      });
    }

    let timestamp = moment().format("DDMMYY_HHmmss");
    var blob = new Blob([csv]);
    var a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    if (multipleFileFlag) {
      a.download =
        this.fileName +
        "_" +
        timestamp +
        "_" +
        "File" +
        "_" +
        fileNumber +
        ".csv";
    } else {
      a.download = this.fileName + "_" + timestamp + ".csv";
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**Set Notification search parameters for Detailed report**/
  setSearchNotificationParameters() {
    const subscriber = [];
    const eventTypes = [];

    this.subscriberData.forEach((subscriberObj) => {
      subscriber.push(subscriberObj.subscriberCode);
    });
    this.eventsData.forEach((eventObj) => {
      if (this.version == "V2"){
      eventTypes.push(eventObj.eventTypeName);
    }else {
      eventTypes.push(eventObj.eventType);
    }
    });
    const obj = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      eventType: this.version=="V2"?[]:eventTypes,
      merchantId: null,
      subscriberId: subscriber,
      version: this.version == "V2" ? "v2" : "v1",
      status: [],
      notificationId: null,
      sortResultsBy:[{"fieldName":"updatedTimeStamp","ordering":"DESC"}],
      
    };
    if(this.version == "V1"){
      delete obj.sortResultsBy;
    }
    return obj;
  }

  setPaginationNotificationParameters(
    val1: number,
    val2: number,
    csvboolean: boolean
  ) {
    let obj: any;
    const pagObj = {
      offset: val1,
      limit: val2,
    };
    obj = this.setSearchNotificationParameters();
    obj.pagination = pagObj;
    return obj;
  }

  getToDateFormat(dt: any) {
    if (dt) {
      return this.timeESTEDTPipe.convertToUTC(dt + 'T' + this.sharedService.getLocalTimeZone());
    } else {
      return "";
    }
  }
}
