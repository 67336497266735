import { NgModule } from '@angular/core';
import { NgxXml2jsonComponent } from './ngx-xml2json.component';

@NgModule({
  imports: [
  ],
  declarations: [NgxXml2jsonComponent],
  exports: [NgxXml2jsonComponent]
})
export class NgxXml2jsonModule { }
