import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-ngx-xml2json',
  template: `
    <p>
      Ngx-xml2json
    </p>
  `,
  styles: []
})
export class NgxXml2jsonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
